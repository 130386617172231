<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="学生姓名">
              <a-input placeholder="请输入学生姓名" v-model="queryParam.name"></a-input>
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="手机号">
              <a-input placeholder="请输入手机号" v-model="queryParam.phone"></a-input>
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status">
                <a-select-option value="0">注册</a-select-option>
                <a-select-option value="1">同意</a-select-option>
                <a-select-option value="2">拒绝</a-select-option>
                <a-select-option value="3">关闭</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <!-- <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="学生编号">
              <a-input placeholder="请输入学生编号" v-model="queryParam.code"></a-input>
            </a-form-item>
          </a-col>
          <template v-if="toggleSearchStatus">
            <a-col :xl="6" :lg="7" :md="8" :sm="24">
              <a-form-item label="性别">
                <j-dict-select-tag placeholder="请选择性别" v-model="queryParam.gender" dictCode="sex" />
              </a-form-item>
            </a-col>

          </template>-->
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
              <!-- <a @click="handleToggleSearch" style="margin-left: 8px">
                {{ toggleSearchStatus ? '收起' : '展开' }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'" />
              </a> -->
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button type="primary" icon="download" @click="handleDownload()">下载学生注册二维码</a-button>
      <!-- <a-button @click="handleAdd" type="primary" icon="plus">新增</a-button>
      <a-upload
        name="file"
        :showUploadList="false"
        :multiple="false"
        :headers="tokenHeader"
        :action="importExcelUrl"
        @change="handleImportExcel"
      >
        <a-button type="primary" icon="import">导入</a-button>
      </a-upload> -->
      <!--   &lt;!&ndash; 高级查询区域 &ndash;&gt;
        <j-super-query :fieldList="superFieldList" ref="superQueryModal" @handleSuperQuery="handleSuperQuery"></j-super-query>
        <a-dropdown v-if="selectedRowKeys.length > 0">
          <a-menu slot="overlay">
            <a-menu-item key="1" @click="batchDel"><a-icon type="delete"/>删除</a-menu-item>
          </a-menu>
          <a-button style="margin-left: 8px"> 批量操作 <a-icon type="down" /></a-button>
        </a-dropdown>-->
    </div>

    <!-- table区域-begin -->
    <div>
      <!--      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
        <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择 <a style="font-weight: 600">{{ selectedRowKeys.length }}</a>项
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>-->

      <a-table
        ref="table"
        size="middle"
        :scroll="{ x: true }"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange"
      >
        <!--        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"-->

        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="registerStatus" slot-scope="text">
          {{ text == 0 ? '已注册' : text == 1 ? '审批通过' : text == 2 ? '审批未通过' : '已关闭' }}
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px; font-style: italic">无图片</span>
          <img
            v-else
            :src="getImgView(text)"
            height="25px"
            alt=""
            style="max-width: 80px; font-size: 12px; font-style: italic"
          />
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px; font-style: italic">无文件</span>
          <a-button v-else :ghost="true" type="primary" icon="download" size="small" @click="downloadFile(text)">
            下载
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record">
          <!-- <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" /> -->
          <a-popconfirm
            title="确定通过申请吗?"
            @confirm="() => updateStatus(record, 1)"
            v-if="record.registerStatus == 0"
          >
            <a>通过</a>
          </a-popconfirm>
         <a-divider type="vertical" v-if="record.registerStatus == 0||record.registerStatus == 2 " />
          <a-popconfirm
            title="确定拒绝申请吗?"
            @confirm="() => updateStatus(record, 2)"
            v-if="record.registerStatus == 0"
          >
            <a>拒绝</a>
          </a-popconfirm>
          <a-popconfirm title="确定关闭吗?" @confirm="() => updateStatus(record, 3)" v-if="record.registerStatus == 2">
            <a>关闭</a>
          </a-popconfirm>
          <a-divider type="vertical" v-if="record.registerStatus == 0||record.registerStatus == 2 " />
          <a class="ant-dropdown-link" @click="handleDetail(record)">详情</a>
          <!-- <a-dropdown>
          <a class="ant-dropdown-link">更多 <a-icon type="down" /></a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="handleDetail(record)">详情</a>
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown> -->
        </span>
      </a-table>
    </div>

    <student-modal ref="modalForm" @ok="modalFormOk"></student-modal>
  </a-card>
</template>

<script>
import '@/assets/less/TableExpand.less'
import { mixinDevice } from '@/utils/mixin'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import StudentModal from './modules/StudentModal'
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil'
import { httpAction, getAction } from '@/api/manage'
export default {
  name: 'StudentList',
  mixins: [JeecgListMixin, mixinDevice],
  components: {
    StudentModal,
  },
  data() {
    return {
      disableMixinParam: true,
      description: '学生管理页面',
      // 表头
      columns: [
        {
          title: '学生姓名',
          align: 'center',
          dataIndex: 'name',
        },
        {
          title: '学生编号',
          align: 'center',
          dataIndex: 'code',
        },
        {
          title: '性别',
          align: 'center',
          dataIndex: 'gender_dictText',
        },
        {
          title: '手机号',
          align: 'center',
          dataIndex: 'phone',
        },
        {
          title:'创建时间',
          align:"center",
          sorter: true,
          dataIndex: 'createTime'
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'registerStatus',
          scopedSlots: { customRender: 'registerStatus' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 147,
          scopedSlots: { customRender: 'action' },
        },
      ],
      url: {
        list: '/ylpp/student/register/list',
        delete: '/ylpp/student/delete',
        deleteBatch: '/ylpp/student/deleteBatch',
        exportXlsUrl: '/ylpp/student/exportXls',
        importExcelUrl: 'ylpp/student/importExcel',
        updateStatus: '/ylpp/student/update/status',
      },
      dictOptions: {},
      superFieldList: [],
    }
  },
  created() {
    this.getSuperFieldList()
  },
  computed: {
    importExcelUrl: function () {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    },
  },
  methods: {
    updateStatus(record, value) {
      httpAction(`${this.url.updateStatus}`, { registerId: record.registerId, status: value }, 'put')
        .then((res) => {
          this.dataSource = [];
          if (res.success) {
            this.loadData()
          }
          this.loading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
          this.loading = false;
        })
    },
    async handleDownload() {
      //location.href=''
      const imgUrl = '/studentRegister.png'
      const response = await fetch(imgUrl)
      const blob = await response.blob()
      // 创建下载链接
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = 'image.png'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      // 释放 Blob URL
      window.URL.revokeObjectURL(url)
    },
    initDictConfig() {},
    getSuperFieldList() {
      let fieldList = []
      fieldList.push({ type: 'string', value: 'name', text: '学生姓名', dictCode: '' })
      fieldList.push({ type: 'string', value: 'code', text: '学生编号', dictCode: '' })
      fieldList.push({ type: 'int', value: 'gender', text: '性别', dictCode: 'sex' })
      fieldList.push({ type: 'string', value: 'idNumber', text: '身份证号', dictCode: '' })
      fieldList.push({ type: 'string', value: 'phone', text: '手机号', dictCode: '' })
      fieldList.push({ type: 'date', value: 'birthday', text: '生日' })
      fieldList.push({ type: 'string', value: 'classId', text: '班级', dictCode: 'tb_class where status=1,name,id' })
      fieldList.push({ type: 'int', value: 'status', text: '状态', dictCode: 'status' })
      this.superFieldList = fieldList
    },
  },
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>