<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="教师姓名">
              <a-input placeholder="请输入教师姓名" v-model="queryParam.name"></a-input>
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="手机号">
              <a-input placeholder="请输入手机号" v-model="queryParam.phone"></a-input>
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status">
                <a-select-option value="0">注册</a-select-option>
                <a-select-option value="1">同意</a-select-option>
                <a-select-option value="2">拒绝</a-select-option>
                <a-select-option value="3">关闭</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="班级" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="departmentId">
              <j-dict-select-tag type="list" v-model="model.departmentId" dictCode="base_department where is_enable=1,name,id" placeholder="请选择班级" />
            </a-form-item>
          </a-col> -->
          <!-- <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="教师编码">
              <a-input placeholder="请输入教师编码" v-model="queryParam.code"></a-input>
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="教师姓名">
              <a-input placeholder="请输入教师姓名" v-model="queryParam.name"></a-input>
            </a-form-item>
          </a-col> -->
          <!--          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="班级名称">
              <a-select
                v-model="queryParam.classIds"
                show-search
                placeholder="请输入班级名称后进行选择"
                style="width: 200px"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                :options="classData"
                @search="handleSearch"
                @change="handleChange"
              >
              </a-select>
            </a-form-item>
          </a-col>-->
          <!-- <template v-if="toggleSearchStatus">
            <a-col :xl="6" :lg="7" :md="8" :sm="24">
              <a-form-item label="手机号">
                <a-input placeholder="请输入手机号" v-model="queryParam.phone"></a-input>
              </a-form-item>
            </a-col>
          </template>-->
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <!-- <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
              <a @click="handleToggleSearch" style="margin-left: 8px">
                {{ toggleSearchStatus ? '收起' : '展开' }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'" />
              </a>-->
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <!-- <a-button @click="handleAdd" type="primary" icon="plus">新增</a-button> -->
      <!--      <a-button type="primary" icon="download" @click="handleExportXls('教师表')">导出</a-button>
      <a-upload name="file" :showUploadList="false" :multiple="false" :headers="tokenHeader" :action="importExcelUrl" @change="handleImportExcel">
        <a-button type="primary" icon="import">导入</a-button>
      </a-upload>-->
      <!-- 高级查询区域 -->
      <!--      <j-super-query :fieldList="superFieldList" ref="superQueryModal" @handleSuperQuery="handleSuperQuery"></j-super-query>
      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="batchDel"><a-icon type="delete"/>删除</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> 批量操作 <a-icon type="down" /></a-button>
      </a-dropdown>-->
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px">
        <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
        <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
        >项
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>

      <a-table
        ref="table"
        size="middle"
        :scroll="{ x: true }"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        class="j-table-force-nowrap"
        @change="handleTableChange"
      >
        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="registerStatus" slot-scope="text">
          {{ text == 0 ? '已注册' : text == 1 ? '审批通过' : text == 2 ? '审批未通过' : '已关闭' }}
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px; font-style: italic">无图片</span>
          <img
            v-else
            :src="getImgView(text)"
            height="25px"
            alt=""
            style="max-width: 80px; font-size: 12px; font-style: italic"
          />
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px; font-style: italic">无文件</span>
          <a-button v-else :ghost="true" type="primary" icon="download" size="small" @click="downloadFile(text)">
            下载
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record">
          <a-popconfirm
            title="确定通过申请吗?"
            @confirm="() => updateStatus(record, 1)"
            v-if="record.registerStatus == 0"
          >
            <a>通过</a>
          </a-popconfirm>
          <a-divider type="vertical" v-if="record.registerStatus == 0" />
          <a-popconfirm
            title="确定拒绝申请吗?"
            @confirm="() => updateStatus(record, 2)"
            v-if="record.registerStatus == 0"
          >
            <a>拒绝</a>
          </a-popconfirm>
          <a-popconfirm title="确定关闭吗?" @confirm="() => updateStatus(record, 3)" v-if="record.registerStatus == 2">
            <a>关闭</a>
          </a-popconfirm>
          <!-- <a @click="handleTools(record,1)">编辑</a>-->
          <a-divider type="vertical" v-if="record.registerStatus == 0||record.registerStatus == 2 " />
          <a @click="handleTools(record, 2)">详情</a>
          <!--          <a-dropdown>
            <a class="ant-dropdown-link">更多 <a-icon type="down" /></a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="handleTools(record,2)">详情</a>
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>-->
        </span>
      </a-table>
    </div>

    <teacher-modal ref="modalForm" @ok="modalFormOk"></teacher-modal>
  </a-card>
</template>

<script>
import { httpAction, getAction } from '@/api/manage'
import '@/assets/less/TableExpand.less'
import { mixinDevice } from '@/utils/mixin'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import TeacherModal from './modules/TeacherModal'
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil'

export default {
  name: 'TeacherList',
  mixins: [JeecgListMixin, mixinDevice],
  components: {
    TeacherModal,
  },
  data() {
    return {
      classData: [],
      disableMixinParam: true,
      description: '教师表管理页面',
      // 表头
      columns: [
        /*  {
            title: '#',
            dataIndex: '',
            key:'rowIndex',
            width:60,
            align:"center",
            customRender:function (t,r,index) {
              return parseInt(index)+1;
            }
          }, */
        // {
        //   title: '用户账号',
        //   align: 'center',
        //   dataIndex: 'userName',
        // },
        {
          title: '教师编码',
          align: 'center',
          dataIndex: 'code',
        },
        {
          title: '教师姓名',
          align: 'center',
          dataIndex: 'name',
        },
        {
          title: '手机号',
          align: 'center',
          dataIndex: 'phone',
        },
        {
          title: '电子邮箱',
          align: 'center',
          dataIndex: 'email',
        },
        {
          title: '性别',
          align: 'center',
          dataIndex: 'gender_dictText',
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'registerStatus',
          scopedSlots: { customRender: 'registerStatus' },
        },
        {
          title: '创建时间',
          align: 'center',
          sorter: true,
          dataIndex: 'createTime',
        },
        /* {
            title:'创建人',
            align:"center",
            dataIndex: 'createBy'
          }, */
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 147,
          scopedSlots: { customRender: 'action' },
        },
      ],
      url: {
        list: '/ylpp/teacher/register/list',
        delete: '/ylpp/teacher/delete',
        deleteBatch: '/ylpp/teacher/deleteBatch',
        exportXlsUrl: '/ylpp/teacher/exportXls',
        importExcelUrl: 'ylpp/teacher/importExcel',
        classPulldownUrl: 'ylpp/tbClass/pulldown',
        updateStatus: 'ylpp/teacher/update/status',
      },
      dictOptions: {},
      superFieldList: [],
    }
  },
  created() {
    this.getSuperFieldList()
  },
  computed: {
    importExcelUrl: function () {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    },
  },
  methods: {
    initDictConfig() {},
    getClass(name) {
      httpAction(`${this.url.classPulldownUrl}?name=${name}`, {}, 'get')
        .then((res) => {
          if (res.success) {
            this.classData = res.result
            this.classData.forEach((item) => {
              item.title = item.name
              item.value = item.id
            })
          } else {
          }
        })
        .finally(() => {
          this.confirmLoading = false
        })
    },
    handleSearch(value) {
      this.getClass(value)
    },
    handleChange(value) {
      //console.log(value + 'change')
    },
    updateStatus(record, value) {
      httpAction(`${this.url.updateStatus}`, { registerId: record.registerId, status: value }, 'put')
        .then((res) => {
          this.dataSource = [];
          if (res.success) {
            this.loadData()
          }
          this.loading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
          this.loading = false;
        })
    },
    //"classIds": [ "1634956026195222530", "1637358796835753986", "1639968819533365250" ]

    handleTools(record, type) {
      getAction('/ylpp/teacher/queryById', { id: record.id }).then((res) => {
        if (res.success) {
          // 后台查询回来的 total，数据总数量
          record = res.result
          if (type == 1) {
            this.handleEdit(record)
          } else if (type == 2) {
            this.handleDetail(record)
          } else if (type == 3) {
            this.updateStatus(record, 1)
            // 审批通过
          } else if (type == 4) {
            // 审批不通过
            this.updateStatus(record, 2)
          }
        } else {
          this.$error({ title: '查询失败', content: res.message })
        }
      })
    },
    getSuperFieldList() {
      let fieldList = []
      fieldList.push({ type: 'string', value: 'userName', text: '用户账号', dictCode: '' })
      fieldList.push({ type: 'string', value: 'code', text: '教师编码', dictCode: '' })
      fieldList.push({ type: 'string', value: 'name', text: '教师姓名', dictCode: '' })
      fieldList.push({ type: 'string', value: 'phone', text: '手机号', dictCode: '' })
      fieldList.push({ type: 'string', value: 'email', text: '电子邮箱', dictCode: '' })
      fieldList.push({ type: 'int', value: 'gender', text: '性别', dictCode: 'sex' })
      fieldList.push({ type: 'int', value: 'status', text: '状态', dictCode: 'status' })
      fieldList.push({ type: 'datetime', value: 'createTime', text: '创建时间' })
      fieldList.push({ type: 'string', value: 'createBy', text: '创建人', dictCode: '' })
      this.superFieldList = fieldList
    },
  },
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>